// PBruno: Relegated to a mobile-specific file b/c, as yet, there'd been no inclusion of the existing _checkout.scss
// file in the em_mobile.scss top-level theme file.
.cart_cross_sell_item {
  float: $ldirection;
  width: 33%;
  margin-bottom: 30px;
}

.cart-item__qty p {
  @include text--short--plain;
  text-align: $ldirection;
}

.checkout,
#address_form_container {
  input[type='tel'] {
    width: 100%;
    color: $color-navy;
  }
}

.checkout-table {
  .cart-item {
    width: 100%;
  }
}

.transaction-details {
  .transaction-item {
    margin-bottom: 10px;
    position: relative;
    .edit-btn {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
}

#order_detail {
  .order-details--summary {
    .order-status-table {
      td {
        white-space: nowrap;
      }
    }
  }
}

.checkout-table {
  .prod-desc {
    .remove_link {
      text-decoration: underline;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

#review-address-info {
  .transaction-item {
    .grey_button {
      text-decoration: underline;
    }
  }
}

#review #promo-message a {
  text-decoration: underline;
}

// Issue related to EMEAMAINT-15999 for mobile
.checkout {
  section {
    &.sign-in-panel {
      display: block;
    }
  }
  .payment-panel {
    .payment-type {
      display: block;
    }
  }
  .go-shopping.disabled {
    background-color: $color-light-gray;
    border: 1px solid;
  }
}

.opc {
  &__shipping {
    .pg_wrapper {
      .checkout {
        .checkout__content {
          .shipping-layout-section,
          .shipping-address-section {
            #address-section-container {
              padding-bottom: 0;
            }
          }
        }
      }
      #billing-address-info-panel {
        .billing-address-section {
          .checkout_billing_address_under_subtitle {
            padding: 5px 20px 0 20px;
            color: rgb(255, 0, 0);
          }
          .checkout__panel-title {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.delivery_text {
  @include swap_direction(margin, 0 20px 20px);
  padding: 10px;
  text-align: center;
  background: $color-gray-background;
  font-size: 14px;
  line-height: 14px;
  color: $color-navy;
  font-weight: normal;
  font-family: $akzidenz;
  opacity: 0.8;
}

.sub_form {
  .delivery_text {
    @include swap_direction(margin, 0 0 20px);
  }
}
