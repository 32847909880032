.device-mobile {
  #colorbox {
    &.welcome15-overlay {
      &.alt_layout1 {
        .email_popover {
          height: 100%;
          .email_popover__social_icons {
            top: auto;
            bottom: 0;
            height: auto;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.contact-us-page {
  .basic-responsive {
    &.gdpr {
      margin-top: -168px;
    }
  }
}

.cart-items,
.checkout-table {
  .price-label {
    text-decoration: none;
  }
}
