#address_form_container {
  input[type='submit'] {
    margin-bottom: 10px;
    width: 100%;
  }
  .button--secondary {
    width: 100%;
  }
}

#address_book {
  #content.sidebar-page__content {
    margin-top: 35px;
  }
}

.sign-in-page {
  .divider {
    display: none;
  }
}
.address-book-page {
  padding-top: 20px;
}
.elc-user-state-logged-in {
  .account-breadcrumb-wrapper {
    .account-breadcrumb {
      background-position: 0 -70px;
    }
  }
}

.signin-overlay-wrapper {
  div {
    &#cboxContent {
      overflow: scroll;
      height: auto !important;
    }
  }
}

.account-mobile-landing-page {
  .account-landing-menu {
    &__item {
      .account-landing-menu {
        &__link {
          background-position: 100% -499px;
        }
      }
    }
  }
}
