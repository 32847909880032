.spp-product__price {
  font-weight: normal;
}

.spp__phone-button,
.quickshop-inline__phone-button {
  display: none;
}

.spp-product__price-size-select-container {
  margin-bottom: 10px;
}
/* Multiple inclusion of estee-base.scss - forced to give important as override doesn't work */
.product-brief__button-quickshop {
  position: relative !important;
}
