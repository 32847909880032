@mixin title-icon {
  position: absolute !important;
  float: #{$ldirection};
  height: 30px;
  width: 30px;
  #{$rdirection}: 0px;
  top: -12px;
}

@mixin title-icon-open {
  background-size: auto;
  background-position: 0 -2957px;
  height: 53px;
  width: 53px;
}

@mixin co_updates_viewcart {
  .checkout-co-enabled {
    &#viewcart {
      .promo-panel,
      #promo-message {
        margin: 10px 10px 20px;
        padding: 15px;
        border: 1px solid $color-navy;
        background-color: $color-gray-cs-side;
        @media #{$medium-up} {
          position: relative;
          margin: 10px 0 15px;
        }
        .loyalty-find-out-more {
          @media #{$medium-up} {
            #{$rdirection}: 45%;
          }
          a {
            color: $color-black;
          }
        }
      }
      #promo-message {
        margin-bottom: 10px;
        p {
          &:nth-last-child(2) {
            margin-bottom: 10px;
          }
        }
      }
      .checkout-header {
        margin-bottom: 15px;
        margin-top: 20px;
      }
      .opc {
        &__footer {
          background-color: $color-navy;
        }
      }
      #viewcart-panel {
        .viewcart-header {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .page-header {
        @media #{$medium-up} {
          padding-bottom: 20px;
        }
      }
      .empty {
        .recommended-products-panel {
          display: block;
          .cart_cross_sell_item {
            padding: 0 20px;
            width: 50%;
            position: relative;
            min-height: 410px;
            text-align: center;
            input[type='submit'] {
              width: 150px;
              height: 50px;
              line-height: normal;
              white-space: normal;
              background: $color-navy;
              color: $color-white;
              position: absolute;
              bottom: 0;
              #{$ldirection}: 10%;
            }
            &:nth-child(3) {
              display: none;
            }
            .item_info {
              text-align: center;
            }
          }
          &__title {
            padding: 20px 20px 0;
          }
        }
        .messages {
          color: $color-red;
          margin: 5px;
        }
        .links-panel {
          display: none;
        }
        #top-viewcart-buttons {
          .continue-buttons {
            text-align: center;
            .go-shopping {
              width: 95%;
            }
          }
          .btn-viewcart-checkout {
            position: initial;
            box-shadow: none;
            a {
              &.btn {
                display: inline-block;
                border: none;
              }
            }
          }
          .btn-viewcart-bestseller {
            margin-bottom: 15px;
          }
        }
        .checkout {
          &__content {
            border-#{$rdirection}: transparent;
            box-shadow: none;
            width: 100% !important;
            .empty-cart {
              text-align: center;
              margin-top: 10px;
              h4 {
                color: $color-black;
              }
            }
          }
          &__sidebar {
            display: none;
          }
        }
        .signin-to-see-cart {
          margin: 0 10px;
          @media #{$medium-up} {
            margin: 20px;
          }
        }
      }
    }
    .samples-panel {
      border-top: 1px solid $color-navy;
      @media #{$medium-up} {
        border-top: 1px solid $color-border-grey;
      }
      &__title {
        text-align: #{$ldirection};
        padding: 10px 20px;
        text-transform: uppercase;
        margin: 10px 0;
        @media #{$medium-up} {
          font-size: 22px;
          padding: 10px;
          margin: 0;
        }
      }
      .samples-top {
        border-bottom: none;
        padding-bottom: 0;
      }
      .remaining {
        text-transform: initial;
      }
      .available,
      .max {
        font-size: 14px;
        padding: 0 20px;
        line-height: normal;
        @media #{$medium-up} {
          font-size: 16px;
        }
      }
      .sample-products {
        .slick-list {
          margin-top: 15px;
          .slick-track {
            display: flex;
            overflow: auto;
            flex-wrap: wrap;
            @media #{$medium-up} {
              overflow: initial;
            }
          }
          .slick-slide {
            display: flex;
            float: none;
            height: auto;
            > div {
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }
          .product {
            display: flex !important;
            height: auto !important;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            .product-img {
              width: 100%;
              max-width: 146px;
              max-height: 200px;
            }
            .details {
              display: flex;
              flex-direction: column;
              align-items: stretch;
              justify-content: space-between;
              gap: 10px;
              width: 100%;
              height: 100%;
              .product-name {
                font-size: 14px;
                padding-inline: 30px;
              }
            }
            .sample-sku-list {
              .sample-select-button {
                padding: 0 10%;
              }
            }
            .product-subhead.sub-name {
              padding-inline: 30px;
            }
          }
        }
        .slick-prev {
          top: 44%;
          z-index: 1;
          #{$ldirection}: 0;
          height: 30px;
          background: url('/media/images/global/arrow-prev.png') no-repeat center;
          @media #{$medium-up} {
            #{$ldirection}: -20px;
            height: 40px;
          }
        }
        .slick-next {
          top: 44%;
          #{$rdirection}: 0;
          height: 30px;
          background: url('/media/images/global/arrow-next.png') no-repeat center;
          @media #{$medium-up} {
            #{$rdirection}: -20px;
            height: 40px;
          }
        }
      }
      .product-desc {
        display: none;
      }
    }
    .page-wrapper {
      .offer-code-panel,
      .links-panel {
        padding: 10px 24px;
      }
      .empty {
        .empty-cart {
          .go-shopping {
            background: $color-navy;
            color: $color-white;
            float: none;
            width: 300px;
          }
        }
      }
      .checkout {
        .empty-cart {
          .continue-buttons {
            text-align: center;
            margin-bottom: 10px;
          }
        }
        .checkout {
          &__sidebar {
            margin-top: 0;
            @media #{$medium-up} {
              padding: 0;
            }
            #order-summary-panel {
              margin-top: 20px;
              @media #{$medium-up} {
                margin-top: 0;
              }
              .order-summary-panel {
                &__title {
                  @media #{$medium-up} {
                    padding: 0 20px;
                  }
                }
              }
            }
            .offer-code-panel,
            .links-panel,
            .order-summary-panel {
              &__title {
                position: relative;
                text-transform: uppercase;
                font-weight: normal;
                .title-icon {
                  @include title-icon;
                  &:before {
                    content: '\002B';
                    font-size: 39px;
                    color: $color-black;
                    font-weight: normal;
                  }
                }
                &.open {
                  .title-icon {
                    &:before {
                      content: '\2212';
                    }
                  }
                }
              }
            }
            .order-summary-panel {
              border-top: 1px solid $color-navy;
              border-bottom: 1px solid $color-navy;
              padding-top: 18px;
              background: $color-white;
              font-size: 14px;
              @media #{$medium-up} {
                border-top: 1px solid $color-border-grey;
                border-bottom: 1px solid $color-border-grey;
              }
              .ship-method-group {
                border-bottom: 1px solid $color-navy;
                @media #{$medium-up} {
                  border-bottom: 1px solid $color-border-grey;
                }
              }
              td {
                &:last-child {
                  padding-#{$rdirection}: 10px !important;
                }
              }
              .spacer {
                background-color: transparent !important ;
                padding-bottom: 0 !important;
              }
            }
            #offer-code-panel {
              background: $color-white;
              border-top: 1px solid $color-navy;
              border-bottom: 1px solid $color-navy;
              padding: 0 20px;
              @media #{$medium-up} {
                padding-#{$rdirection}: 0;
                border-top: none;
                border-bottom: 1px solid $color-border-grey;
              }
              .offer_msg {
                margin-bottom: 15px;
              }
              .offer_code {
                padding: 0;
                .offer_code_box {
                  padding-#{$ldirection}: 0;
                  @media #{$medium-up} {
                    padding: 0;
                  }
                }
              }
              .offer-code-panel {
                border-bottom: 1px solid $color-navy;
                @media #{$medium-up} {
                  border-bottom: none;
                }
                &__title {
                  padding: 10px 25px;
                  @media #{$medium-up} {
                    padding: 18px 0;
                  }
                  &.open {
                    padding: 10px 25px 20px;
                    @media #{$medium-up} {
                      padding: 18px 0;
                    }
                  }
                  .title-icon {
                    background-image: none;
                    width: 40px;
                    top: -3px !important;
                    @media #{$medium-up} {
                      top: 7px !important;
                    }
                  }
                }
              }
              .offer_code_form_container {
                float: #{$ldirection};
                padding-#{$rdirection}: 10px;
                width: 60%;
                @media #{$medium-up} {
                  width: 63%;
                }
              }
              input[type='submit'] {
                height: calc(5.4em - 2px);
                width: 40% !important;
                margin: 0 0 20px;
                padding: 0;
                @media #{$medium-up} {
                  height: calc(4.8em - 2px);
                  width: 36% !important;
                }
              }
              #one-offer-only {
                display: inline-block;
                padding: 10px 0 40px;
                width: 100%;
                text-align: center;
                @media #{$medium-up} {
                  padding: 0;
                  margin-bottom: 20px;
                }
                .overlay-link {
                  font-weight: normal;
                  font-style: normal;
                  text-transform: uppercase;
                }
              }
            }
            .links-panel {
              border-bottom: 1px solid $color-navy;
              margin: 0 0 20px;
              @media #{$medium-up} {
                border-bottom: none;
                border-top: none;
                margin-top: 10px;
                clear: both;
              }
              .links_list {
                li {
                  font-size: 14px;
                  line-height: 2.5;
                }
              }
              .links-panel {
                margin: 0;
                &__title {
                  .title-icon {
                    #{$rdirection}: 5px;
                    @media #{$medium-up} {
                      #{$rdirection}: 0;
                    }
                  }
                }
              }
            }
            .viewcart-buttons {
              margin: 0;
              @media #{$medium-up} {
                text-align: center;
                padding: 20px;
                background: $color-gray-cs-side;
                .checkout-buttons {
                  width: 100%;
                  height: 48px;
                  line-height: 4;
                }
              }
              .viewcart-buttons-panel {
                padding: 0;
                margin: 0;
                border-bottom: none;
                border-top: none;
                .continue-buttons {
                  display: none;
                  &.btn-viewcart-checkout {
                    display: block;
                  }
                }
              }
            }
          }
          &__content {
            @media #{$medium-up} {
              border-#{$rdirection}: 1px solid $color-border-grey;
              box-shadow: -6px 0 6px -4px $color-border-grey inset;
              padding-top: 20px;
            }
            #loyalty-offer-panel {
              border-top: 1px solid $color-navy;
              @media #{$medium-up} {
                @include swap_direction(padding, 10px 0 10px 10px);
                border-top: 1px solid $color-border-grey;
                border-bottom: 1px solid $color-border-grey;
                margin-top: 0;
              }
              .offer-code-panel {
                padding: 10px 15px;
                @media #{$medium-up} {
                  padding: 10px 0;
                }
                &__title {
                  padding-bottom: 0;
                  text-align: #{$ldirection};
                  text-transform: uppercase;
                  border-bottom: none;
                  margin-bottom: 0;
                  .title-icon {
                    top: -12px;
                    #{$rdirection}: 7px;
                  }
                }
                .content {
                  @media #{$medium-up} {
                    padding-top: 25px;
                  }
                }
              }
            }
            .viewcart-panel {
              &__title {
                font-size: 28px;
                @media #{$medium-up} {
                  font-size: 40px;
                }
              }
            }
            .order-status-panel,
            .samples-panel,
            .offer-code-panel {
              &__title {
                position: relative;
                .title-icon {
                  @include title-icon;
                  top: -3px;
                  &:before {
                    content: '\002B';
                    font-size: 39px;
                    color: $color-black;
                    font-weight: normal;
                  }
                }
                &.open {
                  .title-icon {
                    &:before {
                      content: '\2212';
                    }
                  }
                }
              }
            }
            .samples-panel {
              &__title {
                .title-icon {
                  #{$rdirection}: 5px;
                }
              }
            }
            #viewcart-panel {
              .checkout-table {
                padding: 0;
              }
              .cart-items {
                border-bottom: none;
              }
              .cart-item {
                padding: 10px 15px;
                border-top: 1px solid $color-navy;
                border-bottom: 1px solid $color-navy;
                margin-bottom: 15px;
                @media #{$medium-up} {
                  padding: 12px 0;
                  margin-bottom: 15px;
                  border-top: 1px solid $color-border-grey;
                  border-bottom: 1px solid $color-border-grey;
                }
                &__thumb {
                  width: 35%;
                  @media #{$medium-up} {
                    padding-#{$rdirection}: 50px;
                  }
                }
                &__actual-price {
                  color: $color-navy;
                  opacity: 0.4;
                }
                &__desc {
                  width: 60%;
                  @media #{$medium-up} {
                    width: 70%;
                  }
                  .name,
                  .price {
                    font-size: 13px;
                    @media #{$medium-up} {
                      padding-top: 10px;
                      padding-#{$ldirection}: 25px;
                      font-size: 15px;
                    }
                    .product {
                      &__price--non-sale {
                        font-weight: normal;
                        color: $color-border-grey;
                      }
                      &__price--sale {
                        font-weight: normal;
                        color: $color-black;
                      }
                    }
                  }
                  .sub-name,
                  .size {
                    padding-top: 5px;
                    font-size: 12px;
                    display: block;
                    @media #{$medium-up} {
                      font-size: 14px;
                    }
                  }
                }
                &__product-name {
                  font-size: 20px;
                }
                &__product-subname {
                  font-size: 14px;
                  padding-top: 5px;
                  display: block;
                }
                &__total {
                  font-size: 14px;
                  padding-top: 9px;
                  padding-#{$rdirection}: 15px;
                  text-transform: none;
                  @media #{$medium-up} {
                    padding-top: 10px;
                    padding-#{$rdirection}: 20px;
                    font-size: 15px;
                  }
                }
                &__color {
                  letter-spacing: inherit;
                  margin-top: 5px;
                }
                &__swatch {
                  height: 16px;
                  width: 16px;
                }
                &__qty {
                  padding-top: 10px;
                  @media #{$medium-up} {
                    padding-top: 5px;
                  }
                  .qty_select {
                    padding-#{$rdirection}: 32px;
                    border-color: $color-black;
                    width: 40%;
                    @media #{$medium-up} {
                      width: auto;
                    }
                    .selectBox-label {
                      border-#{$rdirection}: 1px solid;
                      padding: 10px;
                      font-size: 18px;
                    }
                    .selectBox-arrow {
                      #{$rdirection}: 12px;
                    }
                  }
                  &.sample-qty {
                    padding-top: 7px;
                    @media #{$medium-up} {
                      padding-top: 12px;
                    }
                    label {
                      text-transform: capitalize;
                    }
                  }
                }
                .remove_link {
                  width: 16px;
                  height: 16px;
                  #{$rdirection}: 15px;
                  top: 15px;
                }
              }
            }
            .recommended-products-panel {
              &__title {
                text-align: center;
              }
              .sku-brief-editorial {
                .add {
                  .btn-shopnow {
                    background: $extended_mpp_black_pearl;
                    color: $color-white;
                  }
                }
              }
            }
            .order-summary-panel {
              border-top: 1px solid $color-black;
              @media #{$medium-up} {
                border-bottom: 1px solid $color-border-grey;
              }
              .order_status {
                display: initial;
                #status_table {
                  padding: 0 20px;
                  .label {
                    padding-#{$ldirection}: 0;
                  }
                  .shipform_fields {
                    .ship-method-group {
                      border-bottom: 1px solid $color-navy;
                      @media #{$medium-up} {
                        border-bottom: 1px solid $color-border-grey;
                      }
                    }
                  }
                }
                .gray_background_total {
                  .subtotal {
                    background: none;
                  }
                }
                .subtotal {
                  &.label {
                    padding-#{$ldirection}: 20px;
                    padding-bottom: 0;
                  }
                  &.value {
                    padding-#{$rdirection}: 0;
                    padding-bottom: 0;
                    text-transform: none;
                  }
                }
                #checkout_shipmethod {
                  padding-#{$ldirection}: 0;
                }
              }
              .order-status-panel {
                &__title {
                  padding: 10px 20px;
                  text-transform: uppercase;
                  @media #{$medium-up} {
                    padding: 10px 25px;
                  }
                  .title-icon {
                    #{$rdirection}: 5px;
                    @media #{$medium-up} {
                      #{$rdirection}: 0;
                    }
                  }
                }
              }
            }
            .viewcart-buttons {
              .continue-checkout {
                @media #{$medium-up} {
                  width: 315px;
                  height: 48px;
                  line-height: 4;
                }
              }
            }
          }
        }
        .expando-block {
          .expando-block {
            &__content {
              display: none;
            }
          }
          &.expando-block {
            &--expanded {
              .expando-block {
                &__content {
                  display: block;
                }
              }
            }
          }
        }
        .shipping-msg {
          margin: 0 0 10px;
          padding: 15px;
          border: 1px solid $color-navy;
          background-color: $color-gray-cs-side;
          width: 100%;
          @media #{$medium-up} {
            width: 95%;
          }
        }
      }
    }
    &#signin {
      .page-wrapper {
        @media #{$medium-up} {
          background-color: $color-background-grey;
        }
        .checkout {
          &__content {
            &.sign-in-page {
              @media #{$medium-up} {
                width: 50%;
              }
              .sign-in-panel {
                background: $color-white;
                margin: 25px 0;
                border-bottom: none;
                .new-or-returning-radios {
                  text-align: center;
                  margin-top: 20px;
                  .active {
                    &.form-item {
                      border-bottom: 2px solid $color-navy;
                    }
                  }
                  .form-item {
                    border-bottom: 1px solid $color-border-grey;
                    width: 47%;
                    @media #{$medium-up} {
                      width: 45%;
                    }
                    label {
                      font-size: 15px;
                    }
                    &.new-customer {
                      label {
                        width: 50%;
                        padding-#{$ldirection}: 0;
                        @media #{$medium-up} {
                          width: 100%;
                          padding-#{$ldirection}: 24px;
                        }
                      }
                    }
                  }
                }
                .checkout-header {
                  display: none;
                }
                .checkout {
                  &__new-account,
                  &__return-user {
                    margin-#{$ldirection}: auto;
                    margin-#{$rdirection}: auto;
                    float: none;
                    padding: 0;
                    border-#{$ldirection}: none;
                    width: 100%;
                    @media #{$medium-up} {
                      width: 75%;
                      min-height: auto;
                    }
                    .checkout {
                      &__subtitle {
                        text-align: center;
                        font-weight: normal;
                        font-size: 28px;
                        margin-bottom: 10px;
                        @media #{$medium-up} {
                          font-size: 36px;
                          margin-bottom: 20px;
                        }
                      }
                    }
                    .form-text {
                      border: 1px solid $color-black;
                    }
                    .info-link-gdpr {
                      color: $color-black;
                      margin-bottom: 20px;
                    }
                    .new-account,
                    .return-user {
                      &__submit {
                        margin-#{$ldirection}: 0;
                        #{$ldirection}: 0;
                        height: 48px;
                        @media #{$medium-up} {
                          position: static;
                          line-height: 4;
                        }
                      }
                    }
                  }
                  &__return-user {
                    @media #{$medium-up} {
                      min-height: auto;
                    }
                  }
                }
                .social-login {
                  &__container {
                    width: 100%;
                    @media #{$medium-up} {
                      width: 75%;
                    }
                    .social-login-container {
                      &__social-network-button_facebook {
                        background: $color-blue-fb url(/media/images/social_login/fb_icon.svg) 0
                          center no-repeat;
                        background-size: 30px 30px;
                        width: 100%;
                        float: #{$ldirection};
                        .fb-line-separation {
                          border-#{$ldirection}: 1px solid $color-white;
                          position: relative;
                          #{$ldirection}: 45px;
                          float: #{$ldirection};
                          height: 45px;
                        }
                      }
                      &__social-network-button-label {
                        text-transform: uppercase;
                        line-height: 3;
                        color: $color-white;
                        padding-#{$ldirection}: 25px;
                        @media #{$medium-up} {
                          padding-#{$ldirection}: 15px;
                        }
                      }
                    }
                    .fb-overlay-container {
                      height: 45px;
                      overflow: hidden;
                      border: none;
                      padding: 0;
                      position: relative;
                      width: 100%;
                    }
                  }
                  .tooltip {
                    padding-#{$ldirection}: 29px;
                    font-size: 12px;
                    text-decoration: underline;
                    @media #{$medium-up} {
                      padding-#{$ldirection}: 22px;
                    }
                  }
                  .fb-disclaimer,
                  .email-optin {
                    letter-spacing: 0.25px;
                    line-height: 1.6;
                  }
                  .email-optin {
                    text-transform: capitalize;
                  }
                }
                input[type='radio'] {
                  & ~ label {
                    &:after {
                      background: none;
                      box-shadow: none;
                      border: none;
                    }
                    &:before {
                      box-shadow: none;
                      border: none;
                    }
                  }
                }
                &#sign-in-panel {
                  .error_messages {
                    padding: 0;
                  }
                }
              }
              .sign-in-component {
                &__form {
                  &--registration {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
      .opc {
        &__footer {
          background-color: $color-navy;
        }
      }
    }
    .continue-shopping {
      &.grey_button {
        display: none;
      }
    }
    .sample-products {
      .product-list {
        &:not(.slick-slider) {
          display: -webkit-box;
          width: 100%;
          overflow: hidden;
          .product {
            .product-img {
              img {
                width: auto;
              }
            }
          }
        }
      }
    }
    .shipping-container {
      border-bottom: 1px solid $color-navy;
      float: #{$ldirection};
      width: 100%;
      display: block;
      @media #{$medium-up} {
        @include swap_direction(margin, 10px 0 10px 20px);
        border-bottom: 1px solid $color-border-grey;
      }
    }
    .border-hidden {
      border-top: transparent !important;
      margin-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .background-hidden {
      background: transparent !important;
      padding-bottom: 0 !important;
    }
    .social-login {
      &__divider {
        &:before {
          height: 1px;
          background: $color-border-grey;
        }
      }
    }
    &.co_updates_enabled {
      &.has-mobile-checkout-nav {
        .opc {
          &__footer {
            padding: 30px 24px;
            color: $color-light-blue;
            background-color: $color-navy;
            &-info {
              padding-bottom: 30px;
            }
            .footer-link-container {
              float: $ldirection;
              width: 100%;
              margin-bottom: 20px;
              .footer-link {
                padding: 15px 0;
                width: 48%;
                display: inline-block;
                float: $ldirection;
                text-align: center;
                border: 1px solid $color-light-blue;
                font-size: 12px;
                color: $color-light-blue;
                font-weight: bold;
                text-transform: uppercase;
                height: 46px;
                &.contact-link {
                  float: $rdirection;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .icon {
                  background-size: auto auto;
                  width: 22px;
                  height: 18px;
                  position: absolute;
                  background-image: url('/media/images/global/sprites-s38a9885fce.png');
                  background-repeat: no-repeat;
                }
                .icon-email {
                  background-position: 0 -8995px;
                  #{$ldirection}: 35px;
                }
                .icon-phone {
                  background-position: 7px -3734px;
                  #{$rdirection}: 40%;
                }
                .opc {
                  &__footer-icon-text {
                    padding-#{$ldirection}: 30px;
                  }
                }
                &.email-link {
                  padding: 7px 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                &.contact-link {
                  padding: 14px 0;
                }
              }
            }
            .secure-content {
              @include swap_direction(padding, 20px 0);
              text-transform: uppercase;
            }
          }
          &__footer-copyrighttext {
            text-align: $ldirection;
          }
        }
        .utility-nav__cart {
          @media #{$medium-up} {
            #{$ldirection}: 33px;
          }
          @media #{$xlarge-up} {
            #{$ldirection}: 45px;
          }
        }
      }
    }
  }
}

#payment {
  .checkout-progress-bar {
    &__list {
      li {
        &:last-child {
          .progress_bar_lable {
            width: 80%;
          }
        }
      }
    }
  }
}
