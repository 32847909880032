@mixin opc__updates__checkout-shipping {
  .opc__shipping,
  .opc__payment {
    .address-form__item {
      font-family: $akzidenz;
      font-size: 13px;
      padding: 0;
      margin: 8px 0px 12px 0px;
      .label-content {
        font-weight: normal;
      }
    }
    .info-link,
    #please_note_changes,
    .checkout-required {
      display: none;
    }
    .address_controls {
      .radio {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
    #choose-address {
      .choose-book_txt {
        display: none;
      }
      .delivery_address {
        padding-#{$ldirection}: 5px;
        margin-top: 0;
        width: 245px;
        letter-spacing: 1px;
        text-transform: none;
        font-family: $akzidenz;
        font-size: 13px;
        &::first-line {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      .change_link {
        float: $rdirection;
        color: $color-sky-blue-medium;
        border-bottom: 1px solid $color-sky-blue-medium;
        position: absolute;
        #{$rdirection}: 0;
        text-transform: uppercase;
        font-family: $akzidenz;
        font-size: 13px;
      }
      .radio {
        border-top: 1px solid $color-black;
        label {
          display: flex;
        }
      }
    }
    .billing-info,
    .delivery-panel {
      .change_link {
        float: $rdirection;
        color: $color-sky-blue-medium;
        border-bottom: 1px solid $color-sky-blue-medium;
        text-transform: uppercase;
      }
    }
    .full_divider {
      @include swap_direction(padding, 10px 20px);
    }
    .shipping-panel__title {
      font-size: 21px;
      text-align: center;
      display: block;
      background: none;
      border: none;
      letter-spacing: 0.1em;
    }
    .checkout {
      .checkout__content {
        #links-panel {
          .title-icon {
            top: -3px;
          }
        }
        .checkout-header {
          @include swap_direction(padding, 10px);
          display: block;
          margin: 0;
        }
        .corporate_invoice_under_checkbox_text {
          color: rgb(255, 0, 0);
          clear: both;
        }
      }
      .checkout__sidebar {
        #offer-code-panel {
          @include swap_direction(margin, 0px);
          border-top: 1px solid $color-black;
          .offer-code-panel__title {
            font-size: 21px;
            .title-icon {
              position: relative;
              #{$ldirection}: 25px;
            }
          }
        }
        #links-panel {
          margin-bottom: 25px;
        }
      }
      #billing_address_form_container {
        padding: 20px;
        border-top: 1px solid $color-black;
        border-bottom: 0;
      }
      #bill_to_shipping_container {
        @include swap_direction(padding, 0 20px 20px);
        label {
          font-weight: bold;
          text-transform: none;
          letter-spacing: 1px;
        }
      }
      .default_ship_container {
        label {
          font-weight: bold;
        }
      }
      .gift-wrapper-block {
        padding: 0;
        background: $color-gray-background;
        .checkout__panel-title {
          @include swap_direction(padding, 15px 20px);
          margin: 0;
        }
        .expando-block__content {
          padding: 0px;
        }
        .gift-wrapper-content-block {
          padding: 20px;
        }
      }
      #offer-code-panel {
        &.expando-block {
          .expando-block__icon {
            transform: rotate(270deg);
          }
        }
        &.expando-block--expanded {
          .expando-block__icon {
            transform: rotate(180deg);
          }
        }
      }
      .viewcart-panel {
        &.expando-block {
          .expando-block__icon {
            transform: rotate(360deg);
          }
        }
        &.expando-block--expanded {
          .expando-block__icon {
            transform: rotate(180deg);
          }
        }
      }
      .order_status--shipping {
        padding-#{$rdirection}: 0px;
        border-bottom: none;
        border-top: 1px solid $color-black;
        & ~ .expando-block-child {
          display: none;
        }
        &.expando-block--expanded ~ .expando-block-child {
          display: block;
        }
      }
    }
    .newsletter-info__item,
    .sms-promotions__item {
      @include swap_direction(padding, 15px 0 15px 0);
      margin: 0;
    }
    .email_promotions {
      @include swap_direction(padding, 15px 0);
    }
    .city_container {
      margin-bottom: 10px;
    }
    #address-section-container {
      .address_form_container {
        border: 0;
        .radio > div {
          margin-bottom: 10px;
        }
        padding: 0;
      }
    }
    #shipping-address,
    #billing-address-info-panel {
      .billing-address-display {
        letter-spacing: 1px;
        text-transform: none;
        font-family: $akzidenz;
        font-size: 13px;
        .co_default {
          font-weight: 700;
        }
        &::first-line {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      .default_ship_container,
      .address_form_container {
        .label-content {
          font-family: $brandon-text-bold;
        }
        input[type='checkbox'] {
          ~ label {
            word-break: break-all;
          }
        }
      }
      .billing-address-section {
        &.border-top {
          border-top: 1px solid $color-black;
        }
        .checkout__panel-header {
          padding-#{$ldirection}: 40px;
          padding-top: 20px;
        }
      }
      .address {
        .form_element {
          .google_autocomplete__field::placeholder,
          label::before {
            color: $color-gray-dark-border;
          }
          width: 100%;
          padding: 0;
        }
      }
    }
    .checkout-email {
      border-bottom: 1px solid $color-light-gray;
      border-top: 1px solid $color-black;
      background-color: $color-gray-background;
      padding: 20px;
      font-family: $akzidenz;
      font-size: 12px;
      b {
        padding-#{$rdirection}: 17px;
        text-transform: uppercase;
      }
    }
    .order-summary-panel {
      border: 0;
      padding: 0;
      margin: 0;
      &__title {
        @include swap_direction(padding, 15px 20px);
      }
      .orders_items_text {
        display: none;
      }
      .order_status {
        display: flex;
        flex-direction: column;
      }
      .checkout__sidebar {
        order: 2;
      }
      .viewcart-panel {
        order: 3;
      }
    }
    .remove_link {
      font-size: 25px;
      border: 0;
      position: absolute;
      top: 0;
      #{$rdirection}: -15px;
      width: auto;
    }
    .viewcart-panel {
      .cart-item {
        &__price {
          margin: 0;
          padding-top: 0;
        }
      }
      border-bottom: none;
      &.expanded {
        border-bottom: 1px solid $color-black;
      }
    }
    .delivery-panel {
      .address_controls {
        display: none;
      }
    }
    .billing-info {
      .sub-block {
        margin-bottom: 0;
        padding: 0 8px;
        border-bottom: 1px solid $color-black;
      }
      .guest-header {
        border-bottom: none;
      }
    }
    .checkout__panel-header {
      float: inherit;
      font-size: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    #address_form_container {
      border: none;
      .click-and-collect {
        margin: 0;
        .local-collection-link {
          float: $rdirection;
          color: $color-black;
          border-bottom: 1px solid $color-black;
          text-decoration: none;
          font-size: 14px;
          font-weight: normal;
          font-family: $brandon-text;
        }
        .show-collection-point {
          padding: 0;
          border: 0;
          margin: 0;
        }
        .checkout-buttons {
          background-color: $color-black;
          color: $color-white;
          font-size: 12px;
        }
        .delivery_address-header {
          margin-top: 20px;
        }
      }
    }
    #delivery-options {
      .shipform_fields {
        padding: 0 0 15px 0;
        .ship-method-group-label {
          width: 100%;
          float: $ldirection;
          .delivery_option {
            @include swap_direction(margin, 10px 0);
            display: inline-block;
            width: 47%;
            input[type='radio'] {
              visibility: hidden;
              position: absolute;
              &:checked ~ label:after,
              & ~ label:before {
                display: none;
              }
              &:checked + label {
                border: 2px solid $color-black;
                color: $color-black;
              }
            }
            label {
              @include swap_direction(padding, 37% 0 5% 0);
              display: block;
              width: 100%;
              height: 90px;
              border: 1px solid $color-gray-dark-border;
              text-align: center;
              font-size: 12px;
              line-height: 1.2;
            }
          }
          .delivery_option:nth-child(1) {
            label {
              background: url(/media/images/global/home.png) no-repeat center 30%;
            }
          }
          .delivery_option:nth-child(2) {
            float: $rdirection;
            label {
              background: url(/media/images/global/PP.png) no-repeat center 30%;
            }
            .click-and-collect {
              span {
                display: none;
              }
            }
          }
        }
        .ship-method-collection-group + a {
          padding-top: 8px;
          display: none;
        }
      }
      .shipmethod-standard-label {
        @include swap_direction(padding, 15px 20px);
        @include swap_direction(margin, 0 calc(-100vw / 2 + 100% / 2) 10px);
        background: $color-gray-background;
      }
      .hazmat_shipping_message {
        display: none;
      }
    }
    #address-section-container {
      #billing_address_form_container {
        border: 0;
        padding: 0;
        .left {
          display: none;
        }
      }
    }
    #giftwrap-info-panel {
      border-top: 1px solid $color-black;
      #gitft-info-panel {
        border-bottom: 0px;
      }
    }
    .ship-method-group-label {
      .checkout__panel-header {
        float: $ldirection;
        width: 100%;
        padding: 0 0 10px 0;
      }
    }
    .add_new_address {
      label {
        font-family: $akzidenz;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }
    }
    .co_default {
      color: $color-sky-blue-medium;
      text-transform: uppercase;
      font-family: $akzidenz;
      font-size: 12px;
      font-weight: 700;
    }
    .billing-address-display {
      @include swap_direction(padding, 10px 20px);
      font-size: 12px;
    }
    .shipping_address_global {
      @include swap_direction(padding, 10px 25px);
      font-size: 13px;
      border-top: 1px solid $color-black;
      letter-spacing: 1px;
      font-family: $akzidenz;
    }
    .terms_conditions_container {
      padding: 20px;
    }
    .order-summary-panel__title {
      border-top: 1px solid $color-black;
    }
    .new_shipping_address {
      font-size: 14px;
      font-weight: 700;
      padding-#{$ldirection}: 5px;
    }
    #order-summary-panel {
      .loyalty-points-description__panel {
        display: none;
      }
    }
    #status_table {
      width: 100%;
      padding-#{$rdirection}: 24px;
      .subtotal {
        &.value {
          @include swap_direction(margin, 0);
          text-align: $rdirection;
          font-weight: 100;
          line-height: 2.3;
        }
        &.label {
          @include swap_direction(margin, 0);
          text-align: $ldirection;
          font-weight: 100;
          line-height: 2.3;
        }
        .shipmethod_name {
          display: block;
          line-height: 1.2;
        }
      }
      .cart-items {
        border-bottom: 0;
      }
      .gray_background_total {
        .subtotal {
          &.value {
            @include swap_direction(padding, 0);
            font-size: 16px;
            width: 40%;
            font-weight: 700;
            background-color: $color-white;
          }
          &.label {
            font-size: 16px;
            width: 60%;
            font-weight: 700;
          }
        }
      }
    }
    .co_updates_enabled {
      .opc__footer {
        @include swap_direction(padding, 30px 24px);
        color: $color-light-blue;
        background-color: $color-navy;
        &-info {
          padding-bottom: 30px;
        }
        .footer-link-container {
          float: $ldirection;
          width: 100%;
          margin-bottom: 20px;
          .footer-link {
            @include swap_direction(padding, 15px 0);
            width: 48%;
            display: inline-block;
            float: $ldirection;
            text-align: center;
            border: 1px solid $color-light-blue;
            font-size: 12px;
            color: $color-light-blue;
            font-weight: 700;
            text-transform: uppercase;
            height: 46px;
            &.contact-link {
              float: $rdirection;
            }
            &.email-link,
            &.contact-link {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .icon {
              background-size: auto auto;
              width: 22px;
              height: 18px;
              position: absolute;
              background-image: url('/media/images/global/sprites-s38a9885fce.png');
              background-repeat: no-repeat;
              margin-top: -1px;
              &-email {
                background-position: 0 -8995px;
                #{$ldirection}: 40px;
              }
              &-phone {
                background-position: 0 -3733px;
                #{$rdirection}: 140px;
              }
            }
            .opc__footer-icon-text {
              padding-#{$ldirection}: 30px;
            }
          }
        }
        .secure-content {
          @include swap_direction(padding, 20px 0);
          text-transform: uppercase;
        }
        &-copyrighttext {
          text-align: $ldirection;
        }
      }
      #checkout-wrapper {
        .content {
          .group1 {
            display: block;
            .cc-label {
              display: unset;
              padding: 0;
              border-top: 0;
            }
            .cc-logos {
              border-bottom: 0;
              display: unset;
              border-top: 0;
              padding: 0;
              img {
                margin: 0;
                float: none;
                width: auto;
              }
            }
          }
        }
      }
    }
  }
  .opc__shipping {
    .billing-info {
      .sub-block {
        border-bottom: none;
        &.border-bottom {
          border-bottom: 1px solid $color-black;
        }
      }
    }
    .page-wrapper {
      &.page-wrapper-checkout {
        padding-top: 10px;
      }
    }
    .page-wrapper {
      &.page-wrapper-checkout {
        padding-top: 10px;
      }
    }
    .pg_wrapper {
      font-family: $optimaregular;
      #viewcart-panel {
        .remove_link {
          top: 10px;
        }
      }
      .cart-item__desc {
        width: 70%;
        margin-#{$ldirection}: 3%;
        .cart-item__qty {
          padding-top: 5px;
        }
        .adpl {
          select {
            height: 3em;
          }
        }
      }
      .checkout-progress-bar {
        margin-bottom: 5px;
        &__list {
          &-item {
            &::before {
              width: 16px;
              height: 16px;
            }
          }
          .progress_bar_lable {
            text-transform: capitalize;
          }
        }
      }
      .checkout {
        &__content {
          font-size: 10px;
          .ship_method_standard {
            .checkout__panel-header {
              font-family: $optimaregular;
            }
            .ship-method-home-group {
              select {
                height: 27px;
                text-align: center;
                width: 100%;
                font-size: 10px;
                line-height: 27px;
                letter-spacing: 0.3px;
                border-radius: 0px;
                background: url(/media/images/global/icon-dropdown.png) no-repeat $rdirection 4px top 7px;
                font-family: $akzidenz;
              }
            }
          }
          .checkout-page-title {
            font-size: 30px;
            font-family: $optimaregular;
            letter-spacing: normal;
          }
          .shipping-layout-section,
          .shipping-address-section {
            #address-section-container {
              @include swap_direction(padding, 0px 0px 50px 0px);
              .delivery-panel {
                .checkout__panel-header {
                  @include swap_direction(padding, 0px 24px 10px 24px);
                  font-family: $optimaregular;
                }
              }
              .click-and-collect {
                padding-bottom: 4px;
                .address {
                  @include swap_direction(padding, 0);
                  .local-collection {
                    @include swap_direction(padding, 0 20px 10px);
                    font-size: 13px;
                    letter-spacing: 1px;
                    font-family: $akzidenz;
                    .show-collection-point {
                      .checkout__panel-header {
                        @include swap_direction(padding, 0);
                      }
                    }
                    .checkout-buttons {
                      display: block;
                    }
                  }
                  .delivery-panel {
                    padding-bottom: 6px;
                  }
                  .address-form__item {
                    @include swap_direction(padding, 0px 20px);
                    margin-top: 12px;
                    label::before {
                      color: $color-gray-dark-border;
                      line-height: normal;
                      position: relative;
                      top: 7px;
                    }
                  }
                }
              }
              .address {
                @include swap_direction(padding, 10px 20px);
                input,
                select {
                  width: 100%;
                  height: 43px;
                  border-radius: 0px;
                }
                &-form {
                  .form-item {
                    margin-bottom: 10px;
                  }
                }
              }
              .billing-info {
                .address_form_container {
                  padding: 10px;
                }
              }
            }
            .email-promotions-panel {
              .email_promotions {
                @include swap_direction(padding, 0px 0px);
              }
              border-top: 1px solid $color-black;
            }
          }
          .info-link-gdpr.email_promotions {
            @include swap_direction(padding, 20px 24px 0px 24px);
          }
          .expando-block {
            &.loyalty-panel {
              border-top: 1px solid $color-black;
              border-bottom: 0px;
              .checkout__subtitle {
                margin-bottom: 0px;
                font-family: $optimaregular;
                font-weight: normal;
                font-size: 16px;
                letter-spacing: normal;
              }
            }
            &__content {
              .checkout__panel-title {
                font-size: 17px;
              }
              .ship_method_standard {
                display: none;
              }
              .bool-item {
                margin-bottom: 0px;
              }
            }
            .email-promotions-panel__title {
              font-size: 13px;
            }
            .offer-code-panel {
              .offer_code_box {
                padding-bottom: 20px;
                #one-offer-only {
                  position: relative;
                  top: 10px;
                  .offer-code__see-all-offers {
                    @include swap_direction(padding, 5px 0);
                    display: block;
                    color: $color-sky-blue-medium;
                    font-size: 12px;
                  }
                }
              }
            }
          }
          .panel.edit {
            @include swap_direction(padding, 0px);
          }
          .bold-text {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
        .order_status--shipping {
          border-top: none;
          &.expando-block {
            .title-icon {
              position: relative;
            }
          }
        }
        .expando-block.expando-block--expanded {
          .expando-block__content {
            @include swap_direction(padding-#{$ldirection}, 0);
            .delivery_address {
              font-family: $akzidenz;
            }
          }
        }
        .billing-info {
          .full_divider {
            border-bottom: 0px;
          }
        }
        .payment-option-container {
          .payment-option-block {
            .payment-option-header {
              &.checkout__panel-title {
                font-family: $akzidenz;
                font-weight: 700;
                font-size: 14px;
              }
            }
          }
        }
        #choose-address {
          .add_new_address {
            border-top: 1px solid $color-black;
          }
        }
      }
      .co_shipping_hidden {
        display: none;
      }
    }
    .expando-block__content {
      #checkout_shipmethod {
        display: none;
      }
    }
    &#shipping {
      .order_status--shipping {
        padding: 0px;
      }
    }
    &.page-wrapper-checkout {
      .checkout {
        .form_element {
          input[type='tel'],
          input[type='text'] {
            & + label {
              font-size: 12px;
              margin-top: calc(-3.5em - 1px);
            }
          }
          &.google_autocomplete {
            input[type='text'] {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .opc__payment {
    .checkout {
      .expando-block--expanded {
        &.address_section,
        &.shipmethod-block {
          .expando-block__content {
            @include swap_direction(padding, 0 10px 15px 25px);
          }
        }
      }
      .expando-block-child {
        &.viewcart-panel {
          border-bottom: 0;
        }
      }
      .payment-option-container {
        .payment-option-block {
          .payment-option-header {
            &.checkout__panel-title {
              font-family: $akzidenz;
              font-weight: 100;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.co_updates_enabled#shipping {
  .checkout--single-page.checkout {
    #links-panel {
      @include swap_direction(padding, 0 25px);
    }
  }
  .expando-block {
    @include swap_direction(padding, 0 25px);
  }
  #checkout_shipmethod {
    @include swap_direction(padding, 0px 20px);
  }
}

.co_updates_enabled#payment.is-min-nav {
  .checkout__content {
    .checkout__panel-header.checkout__panel-title {
      @include swap_direction(padding, 0 0 0 25px);
      font-size: 16px;
      .title-icon {
        top: 0;
      }
    }
  }
}
