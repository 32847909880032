.device-mobile {
  .pg_wrapper {
    .pg_wrapper {
      padding: 0 7px;
    }
  }
  .registration-page__main-button-wrapper__gdpr {
    padding-left: 0px;
  }
}

.page-wrapper {
  .checkout {
    .offer-code-panel {
      &__title {
        padding-left: 0;
        margin: 0;
        .title-icon {
          background-size: auto auto;
          background-position: 0 -3269px;
          width: 53px;
          height: 53px;
          position: absolute;
          margin-top: -41px;
          right: 7px;
          top: auto;
        }
        &.open {
          .title-icon {
            background-size: auto auto;
            background-position: 0 -2957px;
          }
        }
      }
    }
  }
}
