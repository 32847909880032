.h-padding25 {
  padding-#{$ldirection}: 25px;
  padding-#{$rdirection}: 25px;
}

.checkout__return-user,
.checkout__new-account {
  padding-bottom: 25px;
}

.device-mobile {
  .footer-main-menu {
    clear: both;
  }
}

.responsive-carousel {
  .slide__caption {
    font-size: 35px;
  }
}

.sppmg {
  &.font-size-3,
  &.font-size-4,
  &.font-size-5 {
    .sppmg__placeholder {
      .sppmg__copy {
        font-size: 2.1375vw;
      }
    }
  }
}

.viewcart-paypal-button {
  .terms-conditions {
    margin-top: 10px;
    input {
      position: inherit;
      float: $ldirection;
    }
    &-txt {
      margin-#{$ldirection}: 15px;
    }
  }
}
