$helpful: 'Hjælpsom (';
$nothelpful: 'Ikke hjælpsom (';
$filter: 'FILTER :';
$out-of: ' ud af 5';

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 60%;
          .pr-multiselect-button-alder {
            &:before {
              content: $filter;
            }
          }
          .pr-multiselect-button {
            margin: 5px 0;
            min-width: 100px;
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: $helpful;
                &:after {
                  content: ')';
                }
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: $nothelpful;
                &:after {
                  content: ')';
                }
              }
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      &-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-rating-decimal {
              &:after {
                content: $out-of;
              }
            }
          }
        }
      }
    }
  }
}
