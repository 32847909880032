.product-foundation-vto {
  &__btn-container {
    .see-my-perfect,
    .see-all-shades {
      font-size: 12px;
    }
  }
  &__mobile-ctrl {
    .container {
      .product-full {
        &__add-button {
          @include swap_direction(padding, 0 10px);
          font-size: 11px;
          line-height: normal;
          margin-bottom: 5px;
        }
      }
      .product-sub-heading,
      .product-name {
        font-size: 12px;
      }
      .product-price {
        .sale-price {
          display: inline-block;
        }
      }
    }
  }
  &__container {
    ul.shade-list-vto {
      li {
        .vto-filter-text {
          @include transform(translateX(-50%));
          font-size: 12px;
          position: absolute;
          #{$ldirection}: 50%;
        }
        &.vto-filter {
          &.first-filter {
            margin-#{$ldirection}: 10px;
          }
        }
      }
    }
  }
}
