.page-wrapper {
  #myaccount-wrapper {
    padding: 35px 0px;
  }
}

.contact-us-page {
  .contact-form {
    &__section {
      .contact-form__sub-question-type {
        label[for='question-type-2-d'] {
          display: none;
        }
      }
    }
  }
}

.email_popover {
  .email_popover {
    &__social_icons {
      .twitter,
      .pinterest,
      .youtube {
        display: none;
      }
    }
  }
}

.ff-results {
  .ff-results {
    &__regimen-steps,
    &__matches-sub-headline {
      display: none;
    }
  }
}

.device-mobile {
  #colorbox {
    &.welcome15-overlay {
      #cboxLoadedContent {
        .email_popover {
          .email_popover__social_icons {
            top: auto;
          }
          &__shop_now_link {
            color: $color-white;
            font-weight: normal;
            text-decoration: none;
            a {
              text-decoration: underline;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  #sign-in-panel {
    .checkout {
      &__new-account,
      &__return-user {
        input.error[type='email'] {
          border: 1px $color-red solid;
        }
      }
      &__return-user {
        input.error[type='password'] {
          border: 1px $color-red solid;
        }
      }
    }
  }
  .footer-phone {
    display: none;
  }
}

.section-boutique {
  .page-wrapper {
    .boutique-lip-potion-page {
      .lip-potion__frame {
        #lip-potion__frame5Row1 {
          display: none;
        }
      }
    }
  }
}

.add_cart_response {
  #cboxLoadedContent {
    margin: 35px 0 0 0;
  }
}

.page-sticky-footer {
  .page-sticky-footer__right {
    .menu {
      li {
        display: none;
        &.first,
        &.last {
          display: inline-block;
        }
      }
    }
  }
}

.footer-social-links {
  width: 16px;
}

.welcome15-overlay.alt_layout1 {
  .email_popover {
    .email_popover__content {
      p:nth-last-child(2) {
        text-transform: lowercase;
      }
    }
  }
}

.mpp-collections-double-wear-page {
  .video_local_mobile {
    img {
      width: 100%;
    }
  }
}

.ff-quiz {
  &__slide--4 {
    .ff-quiz__question {
      li:nth-child(6) {
        display: none;
      }
    }
  }
}

.landing-best-sellers-page {
  .field-mobile-template {
    .mpp {
      h2.mpp__header {
        margin-top: 0px;
      }
    }
  }
}

.section-esteestories {
  .ee-landing {
    &__tile--small {
      img {
        width: auto;
      }
    }
  }
}
